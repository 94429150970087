/* abril-fatface-regular - latin */
@font-face {
	font-family: 'Abril Fatface';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/abril-fatface-v19-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/abril-fatface-v19-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/abril-fatface-v19-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/abril-fatface-v19-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/abril-fatface-v19-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/abril-fatface-v19-latin-regular.svg#AbrilFatface') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-300 - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/roboto-condensed-v25-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/roboto-condensed-v25-latin-300.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/roboto-condensed-v25-latin-300.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-300.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-300.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/roboto-condensed-v25-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-300italic - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/roboto-condensed-v25-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/roboto-condensed-v25-latin-300italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/roboto-condensed-v25-latin-300italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-300italic.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-300italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/roboto-condensed-v25-latin-300italic.svg#RobotoCondensed')
			format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-condensed-v25-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-italic - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/roboto-condensed-v25-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/roboto-condensed-v25-latin-italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/roboto-condensed-v25-latin-italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-italic.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/roboto-condensed-v25-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700 - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/roboto-condensed-v25-latin-700.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-700.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-700.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700italic - latin */
@font-face {
	font-family: 'Roboto Condensed';
	font-style: italic;
	font-weight: 700;
	src: url('../fonts/roboto-condensed-v25-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local(''), url('../fonts/roboto-condensed-v25-latin-700italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('../fonts/roboto-condensed-v25-latin-700italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-700italic.woff') format('woff'),
		/* Modern Browsers */ url('../fonts/roboto-condensed-v25-latin-700italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('../fonts/roboto-condensed-v25-latin-700italic.svg#RobotoCondensed')
			format('svg'); /* Legacy iOS */
}
