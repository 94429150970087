svg {
	width: 40px;
	display: block;
}

.path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
	&.circle {
		&.active {
			-webkit-animation: dash 0.9s ease-in-out;
			animation: dash 0.9s ease-in-out;
		}
		stroke-dashoffset: 1000;
	}
	&.line.active {
		stroke-dashoffset: 1000;
		&.active {
			-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
			animation: dash 0.9s 0.35s ease-in-out forwards;
		}
	}
	&.check {
		stroke-dashoffset: -100;
		&.active {
			-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
			animation: dash-check 0.9s 0.35s ease-in-out forwards;
		}
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
